import React from 'react'

import SEO from 'components/core/SEO'
import { HeroText, Headline, Body, CaptionText, Subtitle } from 'components/ui/Text'

const Typography = () => {
  return (
    <>
      <SEO title='Typography' />
      <main>
        <CaptionText>HeroText</CaptionText>
        <div style={{ height: '1vw' }} />
        <HeroText>We help humanity.</HeroText>
        <div style={{ height: '2vw' }} />
        <CaptionText>Headline</CaptionText>
        <div style={{ height: '1vw' }} />
        <Headline>
          Access XR PCs <br /> from anywhere.
        </Headline>
        <div style={{ height: '2vw' }} />
        <CaptionText>Subtitle</CaptionText>
        <div style={{ height: '1vw' }} />
        <Subtitle>
          We help people <br />
          connect from all <br />
          dimensions, as if they <br />
          were in person.
        </Subtitle>
        <div style={{ height: '2vw' }} />
        <CaptionText>Body</CaptionText>
        <div style={{ height: '1vw' }} />
        <Body>
          Pluto is helping you access the power of XR PCs, <br />
          regardless of what kind of device you have. Stream and <br />
          play high-end PC VR games over the internet without <br />
          needing a computer.
        </Body>
        <div style={{ height: '2vw' }} />
        <CaptionText>Caption</CaptionText>
        <div style={{ height: '1vw' }} />
        <CaptionText>Caption.</CaptionText>
        <div style={{ height: '2vw' }} />
      </main>
    </>
  )
}

export default Typography
